.cssfx-twin-spin {
  display: flex;
  width: var(--width);
  height: var(--height);
  border: 3px solid transparent;
  border-top-color: var(--color);
  border-bottom-color: var(--color);
  border-radius: 50%;
  animation: cssfx-twin-spin 1.5s linear infinite;
}

.cssfx-twin-spin:before {
  content: "";
  display: block;
  margin: auto;
  width: calc(var(--width) * 0.75 / 3.5);
  height: calc(var(--width) * 0.75 / 3.5);
  border: 3px solid var(--color);
  border-radius: 50%;
  animation: cssfx-twin-spin-pulse var(--duration) alternate ease-in-out
    infinite;
}

@keyframes cssfx-twin-spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes cssfx-twin-spin-pulse {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
