.cssfx-bouncing-balls {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.cssfx-bouncing-balls div {
  width: var(--width);
  height: var(--height);
  border-radius: 50%;
  background-color: var(--color);
}

.cssfx-bouncing-balls div:nth-of-type(1) {
  transform: translateX(-100%);
  animation: cssfx-bouncing-balls-left-swing var(--duration) ease-in alternate
    infinite;
}

.cssfx-bouncing-balls div:nth-of-type(3) {
  transform: translateX(0);
  animation: cssfx-bouncing-balls-right-swing var(--duration) ease-out alternate
    infinite;
}

@keyframes cssfx-bouncing-balls-left-swing {
  50%,
  100% {
    transform: translateX(0);
  }
}

@keyframes cssfx-bouncing-balls-right-swing {
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
