.cssfx-fading-dots {
  width: var(--width);
  height: var(--height);
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
}

.cssfx-fading-dots > div {
  width: calc(var(--width) / 6);
  height: calc(var(--height) / 6);
  background-color: var(--color);
  border-radius: 50%;
  animation: cssfx-fading-dots-fade var(--duration) alternate ease-in-out
    infinite;
}

.cssfx-fading-dots > div:nth-of-type(2),
.cssfx-fading-dots > div:nth-of-type(4) {
  animation-delay: calc(var(--duration) / 6);
}

.cssfx-fading-dots > div:nth-of-type(3),
.cssfx-fading-dots > div:nth-of-type(5),
.cssfx-fading-dots > div:nth-of-type(7) {
  animation-delay: calc(var(--duration) / 3);
}

.cssfx-fading-dots > div:nth-of-type(6),
.cssfx-fading-dots > div:nth-of-type(8) {
  animation-delay: calc(var(--duration) / 2);
}

.cssfx-fading-dots > div:nth-of-type(9) {
  animation-delay: calc(var(--duration) * 2 / 3);
}

@keyframes cssfx-fading-dots-fade {
  to {
    opacity: 0.2;
  }
}
