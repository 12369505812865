.cssfx-bar-wave {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: var(--width);
}

.cssfx-bar-wave span {
  width: calc(var(--width) / 4 * 3 / 4);
  height: var(--height);
  background-color: var(--color);
}

.cssfx-bar-wave span:nth-of-type(1) {
  animation: cssfx-bar-wave-grow var(--duration) -0.45s ease-in-out infinite;
}

.cssfx-bar-wave span:nth-of-type(2) {
  animation: cssfx-bar-wave-grow var(--duration) -0.3s ease-in-out infinite;
}

.cssfx-bar-wave span:nth-of-type(3) {
  animation: cssfx-bar-wave-grow var(--duration) -0.15s ease-in-out infinite;
}

.cssfx-bar-wave span:nth-of-type(4) {
  animation: cssfx-bar-wave-grow var(--duration) ease-in-out infinite;
}

@keyframes cssfx-bar-wave-grow {
  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2);
  }
}
