.cssfx-messaging-balls {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: calc(var(--width) * 0.5);
}

.cssfx-messaging-balls div {
  width: var(--width);
  height: var(--height);
  border-radius: 50%;
  background-color: var(--color);
  transform: translateY(-100%);
  animation: cssfx-messaging-balls-move var(--duration) ease-in-out alternate
    infinite;
}

.cssfx-messaging-balls div:nth-of-type(1) {
  animation-delay: calc(var(--duration) * -0.5);
}

.cssfx-messaging-balls div:nth-of-type(2) {
  animation-delay: calc(var(--duration) * -0.25);
}

@keyframes cssfx-messaging-balls-move {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
}
