.cssfx-fading-balls {
  width: calc(var(--width) * 4);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.cssfx-fading-balls div {
  width: var(--width);
  height: var(--height);
  border-radius: 50%;
  background-color: var(--color);
  animation: cssfx-fading-balls-fade var(--duration) ease-in-out alternate
    infinite;
}

.cssfx-fading-balls div:nth-of-type(1) {
  animation-delay: calc(var(--duration) * -0.5);
}

.cssfx-fading-balls div:nth-of-type(2) {
  animation-delay: calc(var(--duration) * -0.25);
}

@keyframes cssfx-fading-balls-fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
