.cssfx-flipping-square-box {
  perspective: 120px;
}

.cssfx-flipping-square-plane {
  width: var(--width);
  height: var(--height);
  background-color: var(--color);
  transform: rotate(0);
  animation: cssfx-flipping-square-flip var(--duration) infinite;
}

@keyframes cssfx-flipping-square-flip {
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(180deg) rotateX(180deg);
  }
}
