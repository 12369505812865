.cssfx-circular-progress-svg {
  width: var(--width);
  height: var(--height);
  transform-origin: center;
  animation: cssfx-circular-progress-rotate var(--duration) linear infinite;
}

.cssfx-circular-progress-circle {
  fill: none;
  stroke: var(--color);
  stroke-width: 3;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: cssfx-circular-progress-dash calc(var(--duration) * 3 / 4)
    ease-in-out infinite;
}

@keyframes cssfx-circular-progress-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes cssfx-circular-progress-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
}
