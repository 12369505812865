.cssfx-hypnosis-loader {
  position: relative;
  width: var(--width);
  height: var(--height);
}

.cssfx-hypnosis-outer,
.cssfx-hypnosis-middle,
.cssfx-hypnosis-inner {
  border: 3px solid transparent;
  border-top-color: var(--color);
  border-right-color: var(--color);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
}

.cssfx-hypnosis-outer {
  width: var(--width);
  height: var(--height);
  animation: cssfx-hypnosis-rotate var(--duration) linear infinite;
}

.cssfx-hypnosis-middle {
  width: calc(var(--width) * 0.6);
  height: calc(var(--height) * 0.6);
  animation: cssfx-hypnosis-rotate calc(var(--duration) * 7 / 8) linear reverse
    infinite;
}

.cssfx-hypnosis-inner {
  width: calc(var(--width) * 0.8 / 3.5);
  height: calc(var(--height) * 0.8 / 3.5);
  animation: cssfx-hypnosis-rotate calc(var(--duration) * 3 / 4) linear infinite;
}

@keyframes cssfx-hypnosis-rotate {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
