.cssfx-spin-stretch {
  width: var(--width);
  height: var(--height);
  border: 3px solid transparent;
  border-top-color: var(--color);
  border-bottom-color: var(--color);
  border-radius: 50%;
  animation: cssfx-spin-stretch var(--duration) ease infinite;
}

@keyframes cssfx-spin-stretch {
  50% {
    transform: rotate(360deg) scale(0.4, 0.33);
    border-width: 8px;
  }
  100% {
    transform: rotate(720deg) scale(1, 1);
    border-width: 3px;
  }
}
