.cssfx-ring-svg {
  width: var(--width);
  height: var(--height);
  animation: spin var(--duration) ease infinite;
}

.cssfx-ring {
  fill: none;
  stroke: var(--color);
  opacity: 0.7;
  stroke-width: 2;
}

.cssfx-ring-ball {
  fill: var(--color);
  stroke: none;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
