.cssfx-filling-bottle {
  position: relative;
  width: var(--width);
  height: var(--height);
  border: 3px solid var(--color);
  overflow: hidden;
  animation: cssfx-filling-bottle-spin var(--duration) ease infinite;
}

.cssfx-filling-bottle::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: calc(100% + 3px);
  height: calc(100% + 3px);
  background-color: var(--color);
  opacity: 0.75;
  transform-origin: center bottom;
  transform: scaleY(1);
  animation: cssfx-filling-bottle-fill var(--duration) linear infinite;
}

@keyframes cssfx-filling-bottle-spin {
  50%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes cssfx-filling-bottle-fill {
  25%,
  50% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
