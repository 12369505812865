.cssfx-spin {
  border: 3px solid var(--border-color);
  border-top-color: var(--color);
  border-radius: 50%;
  width: var(--width);
  height: var(--height);
  animation: cssfx-spin 1s linear infinite;
}

@keyframes cssfx-spin {
  to {
    transform: rotate(360deg);
  }
}
