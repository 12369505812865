.cssfx-coin-box {
  perspective: 120px;
}

.cssfx-coin {
  width: var(--width);
  height: var(--height);
  border-radius: 50%;
  border: 4px solid var(--color);
  animation: cssfx-coin-spin var(--duration) ease-in-out infinite;
}

@keyframes cssfx-coin-spin {
  to {
    transform: rotateY(540deg);
  }
}
